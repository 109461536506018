.app{
	overflow: hidden;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
 }
 footer{
	margin-top: auto;
 }
.active{
	color: #fff;
}
.container{
	margin: 0 auto;
}
/* .ant-drawer-title{
color: #05d3ac !important;
} */
.ant-drawer-body{
padding-top:10px !important;
}
ul{
margin-bottom: 0 !important;
}

.fon{
	background-image: url('./images/fon.webp');
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 0;
}

.fon:after{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100vh;
	background: #0C142C;
	opacity: .4;
	z-index: -1;
}

/* .ant-drawer-wrapper-body{
	background: #0C142C;
} */
.ant-breadcrumb ol li span {
color:rgb(156 163 175) !important;
font-weight: 300 !important;
} 
.ant-breadcrumb ol li span a {
color:rgb(156 163 175) !important;
font-weight: 300 !important;
} 